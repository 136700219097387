<!--
 * @description: 通用表格组件
 * @author: Long
 * @version: 1.5
 * @updateDate:2022-10-24
 * @updateDate:2022-11-11 更新类型转换、增加操作栏
 * @description: 简单的用法如下:
    tableHeight:表格高度     tableHeaderList:表头  tableData:表格数据  listQuery:分页  pagination:切换分页  tableRowClick:点击行  tableColumnClick:可点击列
    typeConversion:字典转换需要再对应的代码生成页面配置数据源，选择字典分类里对应的字典
    colorConversion:字体颜色需要特殊显示的需要从字典分类里根据值转换对应的颜色
    <basic-table :tableHeight="tableHeight" :tableHeaderList="$jczlLxglTableHeader" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" @tableColumnClick="tableColumnClick"></basic-table >
-->
<template>
  <div class="table_content">
    <u-table use-virtual ref="mainTable"  showBodyOverflow="tooltip" showHeaderOverflow="title" :height="tableHeight" :row-height="30" :data="tableData"  border style="width: 100%;" @row-click="rowClick" @selection-change="handleSelectionChange">
      <!-- 多选 -->
      <u-table-column type="selection" align="center" width="55" :fixed="fix" v-if="hasSelection" key="hasSelection" ></u-table-column>

      <template v-for="(headerItem,index) in tableHeaderList">
        <!-- 漏损预警 -->
        <u-table-column v-if="headerItem.key == 'leakageAlarm'" :label="headerItem.comment" :min-width="calculateWidth(headerItem)" :key="index" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span :class="scope.row.leakageAlarm == '漏损'?'color-danger':''">{{scope.row[headerItem.key]}}</span>
          </template>
        </u-table-column>
        <!-- 通用 -->
        <u-table-column v-else :label="headerItem.comment" :min-width="calculateWidth(headerItem)" :key="index" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <!-- 需要转换的数据 -->
            <template v-if="headerItem.dataSource">
              <!-- 使用字典值直接赋值颜色 -->
              <!-- 可点击 -->
              <span v-if="headerItem.isClick" class="click_css" @click.stop="tableColumnClick(scope.row)" :style="{color:'#'+colorConversion(scope.row[headerItem.key],headerItem.dataSource)}">{{ typeConversion(scope.row[headerItem.key],headerItem.dataSource)}}</span>
              <!-- 不可点击 -->
              <span v-else  :style="{color:'#'+colorConversion(scope.row[headerItem.key],headerItem.dataSource)}">{{ typeConversion(scope.row[headerItem.key],headerItem.dataSource)}}</span>
            </template>
            <!-- 不需要转换直接显示的数据 -->
            <template v-else>
              <!-- 可点击 -->
              <span v-if="headerItem.isClick" class="click_css" @click.stop="tableColumnClick(scope.row)">{{scope.row[headerItem.key]}}</span>
              <span v-else>{{scope.row[headerItem.key]}}</span>
            </template>
          </template>
        </u-table-column>

      </template>

      
      <!-- 操作栏 -->
      <u-table-column label="操作" v-if="hasOperation" fixed="right" align="center" :min-width="calculateWidth({key:'jobState',comment:'操作列'})" key="hasOperation">
        <template slot-scope="scope">
          <el-button @click="checkDetails(scope.row)" type="success" size="mini">详情</el-button>
        </template>
      </u-table-column>
      


    </u-table>
    <pagination v-if="hasPagination" :total="tableTotal" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
      Pagination,
    },
  data() {
    return {
    }
  },
  props:{
    //table表头数据
    tableHeaderList:[String, Object,Array],
    //table数据
    tableData:[String, Object,Array],
    //分页数据
    listQuery:[String, Object,Array],
    // table高度
    tableHeight:{
      type: Number,
      default: 0
    },  
    // 是否有分页
    hasPagination:{
      type: Boolean,
      default: true
    },
    //分页总数
    tableTotal:{
      type: Number,
      default: 0
    },
    // 是否开启多选
    hasSelection: {
      type: Boolean,
      default: false
    },
    // 是否多选固定
    fix: {
      type: Boolean,
      default: false
    },
    //是否有操作栏
    hasOperation: {
      type: Boolean,
      default: false
    },
    //是否是抄表信息
    isCbxx: {
      type: Boolean,
      default: false
    },
    
  },
  filters: {
    //颜色转换
    colorConversion(status){
      const statusMap = {
        // success:绿色;normal:蓝色;info:默认灰色;warning:橘黄;yellow:黄色;danger:红色;prepare:棕色
        'SYS_UploadFileState_Finished': 'color-success',//数据导入状态
        'SYS_UploadFileState_AllFailed': 'color-danger',//数据导入状态
        'SYS_DataRecycle_Type_Household': 'color-success',//数据回收类型
        'SYS_DataRecycle_Type_Meter': 'color-normal',//数据回收类型
        '0': 'color-danger',//是否
        '1': 'color-success',//是否
      }
      return statusMap[status]
    },
  },
  computed:{
    //计算列宽
    calculateWidth(){
      return function (i) {
        return i.columnWidth + 'px' //暂时改为配置列宽，解决条数过大导致渲染过卡
        // if(this.isCbxx){
        //   return i.columnWidth + 'px'
        // }else{
        //   const arr = this.tableData.map(x => x[i.key])
        //   arr.push(i.comment) // 把每列的表头也加进去算
        //   return (this.getMaxLength(arr) + 45) + 'px'//计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
        // }
      }
    },
    //颜色转换
    colorConversion(){
      return function (value,typeId) { 
        var list = this.$store.state.allCategorysData.filter(u=>u.id== typeId)[0].categoryList
        if( list.filter(u=>u.dtCode == value).length>0){
          return   list.filter(u=>u.dtCode == value)[0].dtValue
        }else{
          return ''
        }
      }
    },
    // 类型转换
    typeConversion(){
      return function (value,typeId) { 
        var list = this.$store.state.allCategorysData.filter(u=>u.id== typeId)[0].categoryList
        if( list.filter(u=>u.dtCode == value).length>0){
          return   list.filter(u=>u.dtCode == value)[0].name
        }else{
          return ''
        }
      }
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    // 遍历列的所有内容，获取最宽一列的宽度
    getMaxLength (arr) {
      return arr.reduce((acc, item) => {
        if (item) {
          const calcLen = this.getTextWidth(item)
          if (acc < calcLen) {
            acc = calcLen
          }
        }
        return acc
      }, 0)
    },
    // 使用span标签包裹内容，然后计算span的宽度 width: px
    getTextWidth (str) {
      let width = 0
      const html = document.createElement('span')
      html.innerText = str
      html.className = 'getTextWidth'
      document.querySelector('body').appendChild(html)
      width = document.querySelector('.getTextWidth').offsetWidth
      document.querySelector('.getTextWidth').remove()
      return width
    },
    // 点击行
    rowClick(row) {
      this.$emit('tableRowClick',row)
    },
    //多选
    handleSelectionChange(row){
      this.$emit('handleSelectionChange',row)
    },
    // 切换分页
    handleCurrentChange(val) {
      this.$emit('pagination',val)
    },
    // 可点击列
    tableColumnClick(row){
      this.$emit('tableColumnClick',row)
    },
    // 点击操作查看
    handleClickOperation(row){
      this.$emit('handleClickOperation',row)
    },
    // 点击操作-查看详情
    checkDetails(row){
      this.$emit('checkDetails',row)
    },
  },
  watch: {
    
  },
}
</script>
<style scoped>
  .click_css{
    color: #337ab7;
    cursor: pointer;
  }
</style>
