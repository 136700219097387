var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table_content" },
    [
      _c(
        "u-table",
        {
          ref: "mainTable",
          staticStyle: { width: "100%" },
          attrs: {
            "use-virtual": "",
            showBodyOverflow: "tooltip",
            showHeaderOverflow: "title",
            height: _vm.tableHeight,
            "row-height": 30,
            data: _vm.tableData,
            border: "",
          },
          on: {
            "row-click": _vm.rowClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _vm.hasSelection
            ? _c("u-table-column", {
                key: "hasSelection",
                attrs: {
                  type: "selection",
                  align: "center",
                  width: "55",
                  fixed: _vm.fix,
                },
              })
            : _vm._e(),
          _vm._l(_vm.tableHeaderList, function (headerItem, index) {
            return [
              headerItem.key == "leakageAlarm"
                ? _c("u-table-column", {
                    key: index,
                    attrs: {
                      label: headerItem.comment,
                      "min-width": _vm.calculateWidth(headerItem),
                      "show-overflow-tooltip": "",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.leakageAlarm == "漏损"
                                      ? "color-danger"
                                      : "",
                                },
                                [_vm._v(_vm._s(scope.row[headerItem.key]))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _c("u-table-column", {
                    key: index,
                    attrs: {
                      label: headerItem.comment,
                      "min-width": _vm.calculateWidth(headerItem),
                      "show-overflow-tooltip": "",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              headerItem.dataSource
                                ? [
                                    headerItem.isClick
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "click_css",
                                            style: {
                                              color:
                                                "#" +
                                                _vm.colorConversion(
                                                  scope.row[headerItem.key],
                                                  headerItem.dataSource
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.tableColumnClick(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.typeConversion(
                                                  scope.row[headerItem.key],
                                                  headerItem.dataSource
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            style: {
                                              color:
                                                "#" +
                                                _vm.colorConversion(
                                                  scope.row[headerItem.key],
                                                  headerItem.dataSource
                                                ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.typeConversion(
                                                  scope.row[headerItem.key],
                                                  headerItem.dataSource
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                : [
                                    headerItem.isClick
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "click_css",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.tableColumnClick(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row[headerItem.key])
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row[headerItem.key])
                                          ),
                                        ]),
                                  ],
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
            ]
          }),
          _vm.hasOperation
            ? _c("u-table-column", {
                key: "hasOperation",
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: "center",
                  "min-width": _vm.calculateWidth({
                    key: "jobState",
                    comment: "操作列",
                  }),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.checkDetails(scope.row)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4143126168
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _vm.hasPagination
        ? _c("pagination", {
            attrs: {
              total: _vm.tableTotal,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.handleCurrentChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }